import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import Spinner from '../../Components/Spinner';
import {
  Card,
  FlexColumnCenterCenter,
  FlexRowCenterBetween,
  FlexRowCenterStart,
  Text22,
} from '../../widget';
import {Color, ORDER_STATUS} from '../../constants';
import JsBarcode from 'jsbarcode';
import useModal from '../../Hooks/useModal';
import {RectButton} from '../../Components/RectButton';
import {AutoDelete, CheckCircleOutline} from '@styled-icons/material';
import {OrderCheckValidatePasswordModal} from '../../Components/OrderCheckValidatePasswordModal';
import {RefundOrderConfirmModal} from '../../Components/RefundOrderConfirmModal';
import {getFormat} from '../../Utils/Price';
import {AlertModalContent} from '../../Components/AlertModalContent';
import {navigate} from 'gatsby-link';
import {useOrder} from '../../Hooks/useOrder';
import {useVistaOrder} from '../FilmOrder';
import * as L from '../../Utils/Lang';
import {useOutlet} from 'reconnect.js';
import {getAggregateVistaConcessions} from '../../Utils/Concessions';
import {isNotAllowViewDetail} from '../../Utils/Order';
import moment from 'moment';

const MealOrder = (props) => {
  const {location} = props;
  const [actions] = useOutlet('actions');
  const {id, ret_code = null, ret_msg = null, action = null} = qs.parse(
    location.search,
  );
  const {modalElem, modalRef} = useModal();
  const {order, isLoading, fetchOrderById} = useOrder(id);
  const {vistaOrder, isVistaOrderLoading} = useVistaOrder(order);
  const [retryCount, setRetryCount] = useState(5);

  useEffect(() => {
    if (!!ret_code && ret_code !== '00') {
      modalRef.current.open();
      modalRef.current.set({
        elem: (
          <AlertModalContent
            title="結帳失敗，請嘗試重新付款"
            subtitle={`${ret_msg}`}
            onConfirm={async () => {
              await navigate(`/checkout?id=${id}`);
              modalRef.current.close();
            }}
          />
        ),
      });
    }
  }, [id, modalRef, ret_code, ret_msg]);

  useEffect(() => {
    if (!action || !ret_code) {
      setRetryCount(0);
      return;
    }
    if (!order || !retryCount) {
      return;
    }
    const shouldRetry =
      (action === 'checkout' &&
        ret_code === '00' &&
        order.status !== ORDER_STATUS.TICKET_CHECKIN) ||
      (action === 'refund' &&
        ret_code === '00' &&
        order.status !== ORDER_STATUS.PAYMENT_REFUNDED);

    if (shouldRetry) {
      setTimeout(async () => {
        await fetchOrderById(order.id);
        setRetryCount((prev) => prev - 1);
      }, 3000);
    } else {
      setRetryCount(0);
    }
  }, [order, action, ret_code, fetchOrderById, retryCount]);

  let onOpenPickUpConcession = () => {
    modalRef.current.open();
    modalRef.current.set({
      elem: (
        <OrderCheckValidatePasswordModal
          modalRef={modalRef}
          onPassValidate={async () => {
            await actions.pickUpConcession(id);
            await fetchOrderById(id);
          }}
        />
      ),
    });
  };

  let onOpenOrderRefundModal = () => {
    const now = moment(new Date());
    if (order.isFilmOrder) {
      const restrictedRefundTime = moment(
        order?.sessions?.[0].showDateTime,
      ).add(-30, 'minutes');
      if (now.isAfter(restrictedRefundTime)) {
        modalRef.current.open();
        modalRef.current.set({
          elem: (
            <AlertModalContent
              title="提醒"
              subtitle="請於電影開演前2小時進行線上退款，若超過線上退票規定之時間，請持原消費信用卡與原訂票手機，於電影開演前20分鐘，至購票影城辦理退款。"
              onConfirm={() => {
                modalRef.current.close();
              }}
            />
          ),
        });
        return;
      }
    }
    modalRef.current.open();
    modalRef.current.set({
      elem: (
        <RefundOrderConfirmModal
          fetchOrderById={fetchOrderById}
          modalRef={modalRef}
          order={order}
        />
      ),
    });
  };

  if (isLoading || isVistaOrderLoading || retryCount > 0) {
    return (
      <Fragment>
        <Spinner />
        {modalElem}
      </Fragment>
    );
  }

  if (isNotAllowViewDetail(order.status)) {
    return <Fragment>{modalElem}</Fragment>;
  }

  return (
    <Wrapper>
      <div className="view">
        <StyledCard>
          {order.is_pick_up && (
            <div className="ticket-checking-filter">
              <div>已取餐</div>
            </div>
          )}
          {getAggregateVistaConcessions(vistaOrder.Booking.Concessions).map(
            (c, i) => {
              return (
                <div key={i} className="ticket-property">
                  <div className="name">
                    {L.d({zh: c.DescriptionAlt, en: c.Description})}
                  </div>
                  <div className="quantity">x {c.QuantityBooked}</div>
                  <div className="price">{getFormat(c.PriceInCents)}</div>
                </div>
              );
            },
          )}
          <div className="dashed-sep" />
          <div className="ticket-property">
            <div className="name">總計</div>
            <Text22
              style={{
                color: Color.orange,
                fontWeight: 'bold',
                width: '100%',
                textAlign: 'right',
              }}>
              {getFormat(
                vistaOrder.Booking.Concessions.reduce((acc, cur) => {
                  acc = acc += cur.PriceInCents;
                  return acc;
                }, 0),
              )}
            </Text22>
          </div>
          <div className="dashed-sep" />
          <div className="img-container">
            {!!order.booking_id &&
              order.status === ORDER_STATUS.TICKET_CHECKIN && (
                <Fragment>
                  <div style={{position: 'relative'}}>
                    <div
                      style={{
                        border: '5px solid #414652',
                        borderRadius: 10,
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        position: 'absolute',
                        zIndex: 5,
                      }}
                    />
                    <svg
                      ref={(node) => {
                        if (node) {
                          JsBarcode(`#barcode`, order.booking_id, {
                            fontSize: 12,
                            lineColor: '#000000',
                            width: 1,
                            height: 20,
                          });
                        }
                      }}
                      id={`barcode`}
                    />
                  </div>
                  <div>掃描以使用票券</div>
                </Fragment>
              )}
            {!order.booking_id && (
              <div style={{color: Color.orange}}>須先付款才可取餐</div>
            )}
          </div>
        </StyledCard>
        <div style={{flex: 1}} />
        {order.status === ORDER_STATUS.TICKET_CHECKIN && !order.is_pick_up && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <RectButton
              disabled={order.status !== ORDER_STATUS.TICKET_CHECKIN}
              onClick={onOpenPickUpConcession}
              icon={<CheckCircleOutline size={20} />}
              style={{width: '55%', color: Color.white}}>
              取餐
            </RectButton>
            <RectButton
              disabled={order.status !== ORDER_STATUS.TICKET_CHECKIN}
              onClick={onOpenOrderRefundModal}
              icon={<AutoDelete size={20} />}
              style={{
                width: '40%',
                color: Color.black_50,
                backgroundColor: Color.black_10,
              }}>
              退票
            </RectButton>
          </div>
        )}
      </div>
      {modalElem}
    </Wrapper>
  );
};

const StyledCard = styled(Card)`
  width: 100%;
  text-align: left;
  position: relative;

  & > .ticket-checking-filter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${Color.black_10};
    border-radius: 10px;
    z-index: 10;
    ${FlexColumnCenterCenter};
    & > div {
      font-size: 22px;
      font-weight: bold;
      padding: 10px;
      border-radius: 10px;
      background-color: ${Color.white};
    }
  }
  & > .img-container {
    width: 100%;
    ${FlexColumnCenterCenter};
    & > img {
      width: 210px;
      height: 210px;
      object-fit: contain;
    }
  }
  & .dashed-sep {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed ${Color.black_50};
    margin: 10px 0;
  }
  & .ticket-property {
    width: 100%;
    ${FlexRowCenterStart};
    text-align: left;
    & > .name {
      min-width: 150px;
    }
    & > .quantity {
      min-width: 100px;
    }
    & > .price {
      text-align: right;
      width: 100%;
    }
  }
`;

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  & > .view {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: var(--basePadding);
    min-height: calc(100vh - var(--topNavBarHeight));
    ${FlexColumnCenterCenter};
    & > .buttons {
      ${FlexRowCenterBetween};
    }
  }

  & .slick-slider {
    width: 100%;
    margin-bottom: 10px;
    & button.circle.left {
      z-index: 10;
      left: -15px;
    }

    & button.circle.right {
      z-index: 10;
      right: -15px;
    }
  }
`;
export default MealOrder;
